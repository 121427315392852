// Dependencies
import React, { useState, useEffect } from 'react';
import { Script } from 'gatsby';
import { Cookies } from 'react-cookie-consent';

// Components
import CookieNotice from '../cookieNotice/cookieNotice';

export default function Scripts() {
  const [hasMounted, setHasMounted] = useState(false);

  const [cookiesAccepted, setCookiesAccepted] = useState(
    Cookies.get('cookiesAccepted') && Cookies.get('cookiesAccepted') === 'true',
  );

  const googleAnalyticsMeasurementId =
    process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID;
  const olarkID = process.env.GATSBY_OLARK_SITE_ID;

  const acceptCookies = () => {
    setCookiesAccepted(true);
  };

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) return null;

  return (
    <>
      <CookieNotice
        acceptCookies={acceptCookies}
        cookiesAccepted={cookiesAccepted}
      />

      <Script
        key="microsoftClarityBranch"
        type="text/javascript"
        defer
        dangerouslySetInnerHTML={{
          __html: `if (typeof window !== 'undefined' && typeof window.clarity !== 'undefined') { window.clarity('set', 'Branch', '${process.env.BRANCH}'); }`,
        }}
      />

      {cookiesAccepted && (
        <>
          {!!googleAnalyticsMeasurementId && (
            <Script
              key="gtagConsent"
              type="text/javascript"
              defer
              dangerouslySetInnerHTML={{
                __html: `gtag('consent', 'update', {
              'ad_storage': 'granted',
              'analytics_storage': 'granted',
              'ad_user_data': 'granted',
              'ad_personalization': 'granted'
              });`,
              }}
            />
          )}

          {!!olarkID && (
            <Script
              key="olark"
              type="text/javascript"
              defer
              dangerouslySetInnerHTML={{
                __html: `(function(o,l,a,r,k,y){if(o.olark)return;
              r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0];
              y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r);
              y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)};
              y.extend=function(i,j){y("extend",i,j)};
              y.identify=function(i){y("identify",k.i=i)};
              y.configure=function(i,j){y("configure",i,j);k.c[i]=j};
              k=y._={s:[],t:[+new Date],c:{},l:a};
              })(window,document,"static.olark.com/jsclient/loader.js");

              olark.configure('system.hb_custom_style', {
                general: {
                  fonts: ['Quasimoda', 'sans-serif'],
                }
              });

              olark.configure('system.hb_show_button_text', false);
              olark.configure('system.hb_primary_color', '#e1dddb');

              olark.identify("${olarkID}");`,
              }}
            />
          )}
        </>
      )}
    </>
  );
}
