// Dependencies
import React from 'react';
import { AnimatePresence } from 'framer-motion';

// Components
import Scripts from './src/components/scripts/scripts';

// Contexts
import { MenuContextProvider } from './src/contexts/menu';

// Leave calling card in console
console.log(`

______ _          _     ___  ___      _   _
| ___ (_)        | |    |  \\/  |     | | | |
| |_/ /___  _____| |___ | .  . | __ _| |_| |_ ___ _ __
|  __/| \\ \\/ / _ \\ / __|| |\\/| |/ _\` | __| __/ _ \\ '__|
| |   | |>  <  __/ \\__ \\| |  | | (_| | |_| ||  __/ |
\\_|   |_/_/\\_\\___|_|___/\\_|  |_/\\__,_|\\__|\\__\\___|_|


Like our code? Get in touch!
hello@pixelsmatter.io

`);

export const wrapRootElement = ({ element }) => (
  <MenuContextProvider>{element}</MenuContextProvider>
);

export const wrapPageElement = ({ element }) => (
  <>
    <AnimatePresence mode="wait">{element}</AnimatePresence>

    <Scripts />
  </>
);
