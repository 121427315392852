// Dependencies
import * as React from 'react';
import {
  createContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

const MenuContext = createContext(false);

export function MenuContextProvider({ children }) {
  const [open, setOpen] = useState(false);

  const openMenu = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const closeMenu = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (typeof document !== `undefined`) {
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'initial';
      }
    }
  }, [open]);

  const contextValue = useMemo(
    () => ({
      open,
      setOpen,
      openMenu,
      closeMenu,
    }),
    [open, openMenu, closeMenu],
  );

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  );
}

export default MenuContext;

MenuContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
