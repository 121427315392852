// Dependencies
import React, { useState, useEffect } from 'react';
import ReactCookieConsent, { Cookies } from 'react-cookie-consent';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

// Styles
import CookieNoticeStyles from './cookieNotice.styles';

export default function CookieNotice({ acceptCookies, cookiesAccepted }) {
  const [loaded, setLoaded] = useState(false);
  const [cookiesDeclined, setCookiesDeclined] = useState(
    Cookies.get('cookiesAccepted') || false,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 1000);

    return () => clearTimeout(timer);
  });

  const handleAcceptCookies = () => {
    acceptCookies();

    // Allow scrolling
    if (typeof document !== 'undefined') document.body.style.overflow = 'auto';

    // Allow Microsoft Clarity to store cookies as the user has accepted the notice
    if (typeof window !== 'undefined' && typeof window.clarity !== 'undefined')
      window.clarity('consent');
  };

  const handleDeclineCookies = () => {
    setCookiesDeclined(true);

    // Allow scrolling
    if (typeof document !== `undefined`) document.body.style.overflow = 'auto';
  };

  // Prevent scrolling if cookies have not yet been accepted/declined
  if (typeof document !== `undefined` && !Cookies.get('cookiesAccepted')) {
    document.body.style.overflow = 'hidden';
  }

  return (
    <CookieNoticeStyles
      className={`${loaded ? 'loaded' : ''} ${
        cookiesAccepted ? 'accepted' : ''
      } ${cookiesDeclined ? 'declined' : ''}`}
    >
      <ReactCookieConsent
        disableStyles
        enableDeclineButton={process.env.GATSBY_SITE_CODE !== 'US'}
        cookieValue
        hideOnAccept={false}
        hideOnDecline={false}
        containerClasses="cookie-notice"
        buttonWrapperClasses="btn-wrapper"
        buttonClasses="btn dark small acccept"
        declineButtonClasses="btn small decline"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="cookiesAccepted"
        onAccept={handleAcceptCookies}
        onDecline={handleDeclineCookies}
      >
        <h2 className="heading">We Value your Privacy</h2>
        <p className="text">
          We use cookies, pixels and other technology to collect information
          about your interactions with our site to enhance user experience. We
          do not store cookies or other technologies on your device that track
          you across third-party apps or websites. For more information, please
          see our{' '}
          <Link to="/privacy-policy" className="learn-more">
            Privacy Policy
          </Link>
          .{' '}
          {process.env.GATSBY_SITE_CODE === 'US' && (
            <>
              Click{' '}
              <button
                type="button"
                className="decline"
                onClick={setCookiesDeclined}
              >
                Decline
              </button>{' '}
              to continue without the use of cookies.
            </>
          )}
        </p>
      </ReactCookieConsent>
    </CookieNoticeStyles>
  );
}

CookieNotice.propTypes = {
  acceptCookies: PropTypes.func.isRequired,
  cookiesAccepted: PropTypes.bool.isRequired,
};
